// Dependencies
import React from 'react';

// Components
import { Radio } from '../Radio/Radio';

// Models
import { RadioListProps } from './RadioList.model';

/**
 * Radio List Component
 */
export const RadioList: React.FC<RadioListProps> = ({ items, twoColumn }) => {
  return (
    <div className={`${twoColumn && 'lg:columns-2'}`}>
      {items.map((item, index) => {
        return (
          <Radio
            {...item}
            size={twoColumn ? 'small' : 'medium'}
            key={`radio-${index}`}
          />
        );
      })}
    </div>
  );
};
