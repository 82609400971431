// Dependencies
import React from 'react';

// Components
import { Button } from '../components/Atoms/Button/Button';
import { Heading } from '../components/Atoms/Heading/Heading';
import { Paragraph } from '../components/Atoms/Paragraph/Paragraph';
import { Image } from '../components/Molecules/Image/Image';

// Assets
import logo from '../assets/svg/JPM_Brown_Logo.svg';

export const Complete = () => {
  return (
    <div className="flex h-full w-full items-center justify-center bg-thank-you bg-cover">
      <div className="w-full bg-limestone py-8 px-4 lg:max-w-4xl lg:px-20 lg:text-center">
        <div className="mb-6 h-[30px] w-[146px] lg:mx-auto lg:mb-4">
          <Image url={logo} width={146} height={30} />
        </div>
        <Heading level="h2" textStyle="Heading 3" className="mb-4">
          <span>Thank you for being part </span>
          <br className="lg:hidden" />
          <span className="text-brown-500">of The e-Trading Edit</span>
        </Heading>
        <Paragraph className="mb-4 lg:mb-8">
          Access an advanced portfolio of digitized services, designed to put
          you in control.
        </Paragraph>
        <div className="lg:flex lg:items-center lg:justify-center">
          <Button
            arrowDirection="Right"
            className="mb-4 w-full lg:mr-4 lg:mb-0 lg:w-fit"
            link="https://markets.jpmorgan.com?source=cib_sv_jp_etrading0125"
          >
            Visit J.P. Morgan Markets
          </Button>
          <Button
            arrowDirection="Right"
            variant="secondary"
            className="w-full lg:w-fit"
            link="https://markets.jpmorgan.com?source=cib_sv_jp_etrading10125#execute"
          >
            Visit J.P. Morgan Execute
          </Button>
        </div>
      </div>
    </div>
  );
};
