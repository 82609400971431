// Dependencies
import React, { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';
import { useFormikContext } from 'formik';

// Models
import { SelectProps } from './Select.model';
import { Listbox } from '@headlessui/react';
import { Input } from '../Input/Input';

/**
 * Input Component
 */
export const Select: React.FC<SelectProps> = ({
  name,
  onChange,
  onBlur,
  options,
  placeholder,
  value,
  accompanyingTextField,
}) => {
  const formik = useFormikContext();

  useEffect(() => {
    setShowAccompanyingTextField(value === 'Other');
  }, [value]);

  const [showAccompanyingTextField, setShowAccompanyingTextField] =
    useState<boolean>(false);
  const accompanyingTextFieldRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const accompanyingTextFieldInput =
      accompanyingTextFieldRef.current?.querySelector(
        `[name=${accompanyingTextField?.name}]`
      ) as HTMLInputElement;

    if (value === 'Other' && accompanyingTextFieldInput) {
      accompanyingTextFieldInput.focus();
      accompanyingTextFieldInput.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, showAccompanyingTextField]);

  return (
    <div className="mb-2 block w-full lg:w-[360px] lg:max-w-full">
      <Listbox
        name={name}
        value={value}
        onChange={(value) => {
          formik.setFieldValue(name, value);
          onChange && onChange({ target: { name, value } });
        }}
      >
        {({ open, value }) => (
          <div className="relative mt-1">
            <Listbox.Button
              className={`relative h-11 w-full border border-titanium ${
                open ? 'rounded-t border-x-jet border-t-jet' : 'rounded'
              } ${
                value && !open ? 'bg-charcoal text-white' : 'bg-white'
              } px-2 text-left`}
            >
              <span className="block truncate">
                {value && typeof value === 'string' ? value : placeholder}
              </span>
              <span className="pointer-events-none absolute top-0 right-0 flex h-full items-center pr-2">
                <Icon path={mdiChevronDown} size="1.5rem" />
              </span>
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 max-h-[154px] w-full overflow-auto rounded-b border-x border-b border-jet bg-white text-base">
              {options &&
                options.map((option, index) => (
                  <Listbox.Option
                    key={index}
                    className={({ active }) =>
                      `relative h-11 cursor-pointer select-none p-2 ${
                        active && 'bg-marble'
                      } ${false && 'hidden'}`
                    }
                    value={option.value}
                    disabled={option.value === '---'}
                  >
                    <span className="block-truncate">{option.key}</span>
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </div>
        )}
      </Listbox>
      {accompanyingTextField && showAccompanyingTextField && (
        <div className="my-2" ref={accompanyingTextFieldRef}>
          <Input {...accompanyingTextField} />
        </div>
      )}
    </div>
  );
};
