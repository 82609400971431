import React from 'react';
import { Paragraph } from '../../Atoms/Paragraph/Paragraph';
import { ProgressBarProps } from './ProgressBar.model';

export const ProgressBar: React.FC<ProgressBarProps> = ({
  availableSteps,
  currentStepQuestion,
}) => {
  return (
    <div className="mx-auto py-2.5 lg:max-w-3xl lg:py-0">
      <div className="flex items-center gap-x-0.5 md:gap-x-1 lg:mb-2">
        {[...Array(availableSteps - 1)].map((x, index) => {
          const stepNumber = index + 1;
          return (
            <React.Fragment key={index}>
              {stepNumber < availableSteps && (
                <div className="flex flex-grow">
                  <div
                    className={`h-0.75 w-full ${
                      stepNumber < currentStepQuestion.number && 'bg-green-500'
                    } ${
                      stepNumber >= currentStepQuestion.number && 'bg-titanium'
                    }`}
                  ></div>
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <Paragraph className="font-medium">
        Step {currentStepQuestion.number} of {availableSteps}
      </Paragraph>
    </div>
  );
};
