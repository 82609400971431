import React, { useEffect, useState } from 'react';
import ReactSlider from 'react-slider';
import { SliderProps } from './RangeSlider.model';

function getNumber(number: any, defaultValue: number = 0): number {
  return typeof number !== 'undefined' && !isNaN(number)
    ? Number(number)
    : defaultValue;
}

function getNumberOrEmptyString(number: number | undefined): string {
  return typeof number !== 'undefined' && !isNaN(number)
    ? number.toString()
    : '';
}

export const RangeSlider: React.FC<SliderProps> = ({
  name,
  value,
  minValue = 0,
  maxValue = 100,
  step = 1,
  unit = '%',
  onChange,
}) => {
  const [slidingValue, setSlidingValue] = useState<number>(
    getNumber(value, minValue)
  );
  const [sliderPosition, setSliderPosition] = useState<number>(0);
  const [isSliding, setIsSliding] = useState<boolean>(false);

  useEffect(() => {
    setSlidingValue(getNumber(value, minValue));
  }, [value, minValue]);

  useEffect(() => {
    setSliderPosition((Math.floor(slidingValue / step) * step) / maxValue);
  }, [slidingValue, step, maxValue]);

  const handleChange = (value: number) => {
    onChange({ target: { name, value } });
  };

  return (
    <div className="relative w-full max-w-md">
      <ReactSlider
        min={minValue}
        max={maxValue}
        step={step}
        value={getNumber(value, minValue)}
        onSliderClick={handleChange}
        onChange={setSlidingValue}
        onBeforeChange={() => {
          setIsSliding(true);
        }}
        onAfterChange={(value) => {
          handleChange(value);
          setIsSliding(false);
        }}
        renderTrack={(props, state) => (
          <div
            {...props}
            className={`${props.className} mt-[9px] h-1.5 rounded-full ${
              state.index === 0 ? 'bg-green-600' : 'bg-titanium'
            }`}
          />
        )}
        renderThumb={(props, _state) => (
          <div
            {...props}
            className={`${props.className} h-6 w-6 cursor-pointer rounded-full bg-green-600`}
          />
        )}
        className="w-full"
      />

      {/* Tooltip */}
      {/* mr-6 reduces the available sliding width by the width of the thumb */}
      <div className="relative mr-6">
        <div
          className="absolute -top-10 ml-3 -translate-x-1/2 transform-gpu drop-shadow"
          style={{
            left: `${sliderPosition * 100}%`,
          }}
        >
          <div className="mt-1.5 h-6 whitespace-nowrap rounded bg-white px-1 text-center">
            <input
              type="text"
              inputMode="numeric"
              value={getNumberOrEmptyString(value)}
              placeholder="0"
              className={`m-0 ${
                getNumber(value, minValue) < 10
                  ? 'w-4'
                  : getNumber(value, minValue) >= 100
                  ? 'w-8'
                  : 'w-6'
              } p-0 pr-1 text-right ${isSliding && 'hidden'}`}
              name={name}
              onChange={(e) => {
                // Ensure value is within range
                let value = Number(e.target.value);
                if (isNaN(value)) value = minValue;
                value = Math.min(value, maxValue);
                value = Math.max(value, minValue);
                e.target.value = value.toString();

                // parent callback
                onChange(e);
              }}
            />
            {/* Replace the input with a span while sliding to avoid many Formik updates */}
            <span
              className={`m-0 inline-block ${
                slidingValue < 10 ? 'w-4' : slidingValue >= 100 ? 'w-8' : 'w-6'
              } p-0 pr-1 text-right ${!isSliding && 'hidden'}`}
            >
              {slidingValue}
            </span>
            <span>{unit}</span>
          </div>
          {/* Triangle */}
          <div className="absolute left-1/2 h-0 w-0 -translate-x-1/2 rounded-b border-x-8 border-t-8 border-transparent border-t-white"></div>
        </div>
      </div>
    </div>
  );
};
