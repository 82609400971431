// Dependencies
import React, { useEffect, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Models
import { InputProps } from './Input.model';

/**
 * Input Component
 */
export const Input: React.FC<InputProps> = ({
  autoFocus,
  autoComplete,
  disabled,
  error,
  inputMode,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  onBlur,
  placeholder,
  readOnly,
  required,
  type,
  value,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ error, id, name }));
  }, [id, error, name]);

  // Render the component.
  return (
    <>
      {label && (
        <label htmlFor={id} className="">
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        name={name}
        onChange={onChange.bind(this)}
        onBlur={onBlur.bind(this)}
        aria-invalid={error ? true : false}
        aria-describedby={describedBy}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        required={required}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        value={value ? value : ''}
        inputMode={inputMode ? inputMode : 'text'}
        className={`block h-11 w-full rounded border ${
          error ? 'border-ruby-700' : 'border-titanium'
        } bg-white py-2 px-3 placeholder:font-sans placeholder:text-charcoal focus:bg-marble`}
      />
    </>
  );
};
