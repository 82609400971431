// Dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, getIn, setIn } from 'formik';
import { mdiInformation } from '@mdi/js';
import cn from 'classnames';
import Icon from '@mdi/react';

// Helpers & data
import {
  selectAvailableSteps,
  selectCurrentIndex,
  selectSurveyStarted,
  selectUserReferenceId,
  selectUserSource,
  setStepIndex,
  setSurveyStarted,
} from '../../features/app/appSlice';
import sortedQuestionsData, {
  QuestionData,
} from '../../features/questions/questionsData';
import {
  selectCurrentStep,
  storeAnswerForStep,
  selectLastCompletedStepIndex,
} from '../../features/questions/questionsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import createClient from '../../services/QuestionsApi/clients/questionsApiClient';

// Components
import { Button } from '../../components/Atoms/Button/Button';
import { Heading } from '../../components/Atoms/Heading/Heading';
import { Paragraph } from '../../components/Atoms/Paragraph/Paragraph';
import { CheckboxList } from '../../components/Molecules/Forms/CheckboxList/CheckboxList';
import { RadioList } from '../../components/Molecules/Forms/RadioList/RadioList';
import { RangeSlider } from '../../components/Molecules/Forms/RangeSlider/RangeSlider';
import { Image } from '../../components/Molecules/Image/Image';
import { ProgressBar } from '../../components/Organisms/ProgressBar/ProgressBar';
import { Select } from '../../components/Molecules/Forms/Select/Select';
import { Input } from '../../components/Molecules/Forms/Input/Input';

// Assets
import logo from '../../assets/svg/JPM_Brown_Logo.svg';
import storage from 'redux-persist/lib/storage';
import { DisclaimerDialog } from '../../components/Organisms/DisclaimerDialog/DisclaimerDialog';
import { ErrorMessage } from '../../components/Molecules/Forms/ErrorMessage/ErrorMessage';
import {
  getOtherQuestionName,
  getWhyDidYouChooseThisAnswerQuestionName,
  getQuestionName,
  otherOptionDefaultValue,
  otherOptionPlaceholder,
} from '../../helpers/questionHelper/questionHelper';
import {
  errorValue,
  getErrors,
  otherErrorMessage,
} from '../../helpers/validationHelper/validationHelper';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const StepContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { stepId } = useParams();
  const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentAnswers, setCurrentAnswers] = useState<any>({});
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const currentStepIndex = useAppSelector(selectCurrentIndex);
  const availableSteps = useAppSelector(selectAvailableSteps);
  const lastCompletedStepIndex = useAppSelector(selectLastCompletedStepIndex);
  const userReferenceId = useAppSelector(selectUserReferenceId);
  const surveyStarted = useAppSelector(selectSurveyStarted);
  const source = useAppSelector(selectUserSource);

  const currentStep: QuestionData = useAppSelector(selectCurrentStep);

  const apiClient = createClient();

  const isMobile = useMediaQuery(`(max-width: 768px)`);

  useEffect(() => {
    if (!surveyStarted) {
      apiClient.startSurvey(userReferenceId, source, isMobile);
      dispatch(setSurveyStarted(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const lastCompletedStepNumber =
      sortedQuestionsData[lastCompletedStepIndex].number;
    if (lastCompletedStepNumber < currentStep.number - 1) {
      dispatch(setStepIndex(lastCompletedStepIndex));
    } else {
      const stepNumber = Number(stepId);
      if (stepNumber !== currentStep.number) {
        handleNextSlide();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  function handleNextSlide() {
    navigate(`/steps/${currentStep.number}`);
  }

  function handleComplete() {
    navigate('/complete');

    sortedQuestionsData.forEach((step, stepIndex) => {
      clearStepAnswers(step, stepIndex);
    });

    storage.removeItem('persist:app');
    storage.removeItem('persist:questions');
  }

  function handleBack() {
    // First, check for a second step in this question, and hide that if it is active
    if (showQuestionStep2 === true) {
      setShowQuestionStep2(false);
      return;
    }

    if (currentStepIndex === 0) {
      navigate('/');
    } else {
      dispatch(setStepIndex(lastCompletedStepIndex));
    }
  }

  function getNextStepIndex(values: any): number {
    // Default is the next step which has a higher step number
    const currentStep = sortedQuestionsData[currentStepIndex];
    let nextStepIndex = sortedQuestionsData.findIndex(
      (step) =>
        (currentStep.nextStepId && currentStep.nextStepId === step.id) ||
        step.number > currentStep.number
    );

    // Check if there's a branch which takes you to a sub-step
    for (let i = 0; i < currentStep.questions.length; i++) {
      const question = currentStep.questions[i];
      const questionName = getQuestionName(question, i);
      const answer = getIn(values, questionName)?.toString();
      if (answer && question.settings.options) {
        const selectedOptionWithNextStep = question.settings.options.find(
          (option) => {
            if (!option.nextStepId) return false;
            const optionValue = option.value || option.text;
            return answer === optionValue;
          }
        );

        if (selectedOptionWithNextStep) {
          nextStepIndex = sortedQuestionsData.findIndex(
            (step) => step.id === selectedOptionWithNextStep.nextStepId
          );

          if (nextStepIndex > -1) {
            // We've found a branch so take it
            break;
          }
        }
      }
    }

    // If we took a branch we need to clear any previously-given answers from branches not taken, if any
    for (let i = currentStepIndex + 1; i < nextStepIndex; i++) {
      clearStepAnswers(sortedQuestionsData[i], i);
    }

    return nextStepIndex;
  }

  function clearStepAnswers(step: QuestionData, stepIndex: number) {
    let answers = {};
    step.questions.forEach((question, index) => {
      const questionName = getQuestionName(question, index);
      answers = setIn(answers, questionName, []);
      answers = setIn(answers, getOtherQuestionName(question, index), '');
      answers = setIn(
        answers,
        getWhyDidYouChooseThisAnswerQuestionName(step.id),
        ''
      );
    });
    dispatch(storeAnswerForStep({ stepIndex, answers }));
  }

  useEffect(() => {
    let values: { [key: string]: any } = {};
    currentStep.questions.forEach((question, index) => {
      const questionName = getQuestionName(question, index);
      values = setIn(values, questionName, question.answer);

      const otherQuestionName = getOtherQuestionName(question, index);
      values = setIn(values, otherQuestionName, question.otherAnswer);
    });

    const whyDidYouChooseThisAnswerQuestionName =
      getWhyDidYouChooseThisAnswerQuestionName(currentStep.id);
    values = setIn(
      values,
      whyDidYouChooseThisAnswerQuestionName,
      currentStep.whyDidYouChooseThisAnswer
    );

    setCurrentAnswers(values);
    setNextButtonDisabled(
      Object.keys(getErrors(values, currentStep.questions)).length > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const steppedQuestions = currentStepIndex === 0;
  const questionStep1 = React.useRef<HTMLDivElement>(null);
  const questionStep2 = React.useRef<HTMLDivElement>(null);
  const [questionStep1Height, setQuestionStep1Height] = React.useState(0);
  const [questionStep2Height, setQuestionStep2Height] = React.useState(0);

  useEffect(() => {
    const updateHeights = () => {
      if (questionStep1.current && questionStep2.current) {
        const step1Height = questionStep1.current.clientHeight;
        const step2Height = questionStep2.current.clientHeight;
        if (step1Height <= step2Height) {
          setQuestionStep1Height(step2Height + step1Height);
        } else {
          setQuestionStep1Height(step1Height);
        }
        setQuestionStep2Height(step2Height);
      }
    };

    updateHeights();
    window.addEventListener('resize', updateHeights);

    return () => {
      window.removeEventListener('resize', updateHeights);
    };
  }, [currentStep]);

  const [showQuestionStep2, setShowQuestionStep2] = React.useState(false);

  return (
    <>
      <div className="relative flex min-h-screen w-full flex-col bg-white lg:justify-between lg:bg-marble lg:after:block lg:after:h-1.5 lg:after:w-full lg:after:bg-bronze-gradient">
        <div className="flex flex-wrap justify-between px-4 pt-6 after:mt-3 after:h-px after:w-full after:flex-shrink-0 after:bg-bronze-gradient lg:absolute lg:left-10 lg:top-0 lg:block lg:border-t-8 lg:border-t-jpm-brown lg:px-0 lg:after:hidden">
          <div className="h-[30px] w-[146px] lg:mb-2">
            <Image url={logo} width={146} height={30} />
          </div>
          <Heading textStyle="Heading 5" level="h2" className="text-brown-500">
            The e-Trading Edit
          </Heading>
        </div>
        <div className="flex h-full w-full flex-grow flex-col px-4 pb-8 pt-1 lg:mx-auto lg:max-w-3xl lg:px-0 lg:pb-0 lg:pt-8 xl:max-w-5xl">
          <div className="lg:mb-9 lg:pl-28 min-[1080px]:px-14 xl:px-28">
            <ProgressBar
              availableSteps={availableSteps}
              currentStepQuestion={currentStep}
            />
          </div>
          <div className="flex flex-auto flex-col lg:mb-16 lg:bg-white lg:px-14 xl:px-28">
            <Formik
              initialValues={currentAnswers}
              enableReinitialize
              validate={(values) => {
                const errors = getErrors(values, currentStep.questions);
                setNextButtonDisabled(Object.keys(errors).length > 0);
                // setShowQuestionStep2(values['question-0'] ? true : false);
                return errors;
              }}
              onSubmit={async (values, { resetForm }) => {
                if (isSubmitting) return;

                // First, check for a second step in this question, and show that if it exists
                if (
                  currentStep.showWhyDidYouChooseThisAnswer &&
                  showQuestionStep2 === false
                ) {
                  setShowQuestionStep2(values['question-0'] ? true : false);
                  return;
                }

                setIsSubmitting(true);

                dispatch(
                  storeAnswerForStep({
                    stepIndex: currentStepIndex,
                    answers: values,
                  })
                );

                const currentStepId = currentStep.id;
                let nextStepId = '';
                let nextStepIndex = currentStepIndex;
                let complete = false;
                if (currentStepIndex < sortedQuestionsData.length - 1) {
                  nextStepIndex = getNextStepIndex(values);
                  nextStepId = sortedQuestionsData[nextStepIndex].id;
                } else {
                  complete = true;
                }

                await apiClient
                  .postAnswers(
                    userReferenceId,
                    currentStepId,
                    nextStepId,
                    values
                  )
                  .then(() => {
                    resetForm();
                    // CLear the step 2 animation, so the next question can be seen
                    setShowQuestionStep2(false);
                    if (complete) {
                      handleComplete();
                    } else {
                      dispatch(setStepIndex(nextStepIndex));
                    }
                  })
                  .catch((error) => {
                    // TODO: show the user an error?
                    console.error(error);
                  })
                  .finally(() => {
                    setIsSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldError,
              }) => (
                <form
                  action="POST"
                  onSubmit={handleSubmit}
                  className="flex h-full w-full flex-auto flex-col"
                >
                  <div
                    className={cn(
                      'flex-auto lg:pt-8',
                      steppedQuestions ? 'overflow-hidden' : ''
                    )}
                  >
                    <div
                      style={{
                        transform: showQuestionStep2
                          ? `translateY(-${Math.round(questionStep2Height)}px)`
                          : '',
                        height: currentStep.showWhyDidYouChooseThisAnswer
                          ? `${questionStep1Height}px`
                          : '',
                      }}
                      className={cn(
                        '',
                        steppedQuestions
                          ? 'relative transition-transform duration-500'
                          : ''
                      )}
                    >
                      <div
                        className={cn('', steppedQuestions ? 'relative' : '')}
                        ref={questionStep1}
                      >
                        {steppedQuestions && (
                          <button
                            className={cn(
                              'transition-opacity duration-500',
                              showQuestionStep2
                              ? 'absolute z-10 h-full w-full bg-white opacity-80'
                              : 'opacity-0'
                            )}
                            onClick={() => {
                              setShowQuestionStep2(false);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Tab') {
                                setShowQuestionStep2(false);
                              }
                            }}
                            type="button"
                          />
                        )}
                        <div className="py-4">
                          <Heading
                            level="h2"
                            textStyle="Heading 4"
                            className="mb-2"
                          >
                            {currentStep.title}
                          </Heading>
                          {currentStep.explanationText && (
                            <Paragraph className="mb-2 text-lg font-light">
                              {currentStep.explanationText}
                            </Paragraph>
                          )}
                          {currentStep.helpText && (
                            <Paragraph className="text-base font-medium">
                              {currentStep.helpText}
                            </Paragraph>
                          )}
                          <ErrorMessage
                            currentStep={currentStep}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                        <div className="pb-16 lg:pt-8">
                          {currentStep.questions.map((question, index) => {
                            let formComponent = null;
                            const key = `question-${index}`;
                            const questionName = getQuestionName(
                              question,
                              index
                            );
                            const otherQuestionName = getOtherQuestionName(
                              question,
                              index
                            );

                            const answer = getIn(values, questionName);
                            const otherAnswer =
                              getIn(values, otherQuestionName) || '';

                            const otherError = Object.keys(errors).includes(
                              otherQuestionName
                            )
                              ? otherErrorMessage
                              : '';
                            switch (question.type) {
                              case 'decimal':
                                formComponent = (
                                  <div className="lg:w-96">
                                    <Input
                                      type="text"
                                      inputMode="decimal"
                                      name={questionName}
                                      placeholder={
                                        question.settings.placeholder
                                      }
                                      onChange={(
                                        e: React.ChangeEvent<HTMLInputElement>
                                      ) => {
                                        e.preventDefault();
                                        const { value } = e.target;
                                        if (
                                          question.settings.regex &&
                                          !new RegExp(
                                            question.settings.regex
                                          ).test(value.toString())
                                        ) {
                                          setFieldError(
                                            questionName,
                                            errorValue
                                          );
                                        } else {
                                          setFieldValue(questionName, value);
                                          handleChange(e);
                                        }
                                      }}
                                      onBlur={handleBlur}
                                      value={answer?.toString()}
                                      error={
                                        Object.keys(errors).includes(
                                          questionName
                                        )
                                          ? currentStep.errorMessage
                                          : ''
                                      }
                                    />
                                  </div>
                                );
                                break;
                              case 'radiolist':
                                if (question.settings.options) {
                                  formComponent = (
                                    <RadioList
                                      items={question.settings.options.map(
                                        (opt) => {
                                          const optionValue =
                                            opt.value ||
                                            (opt.specify
                                              ? otherOptionDefaultValue
                                              : opt.text);
                                          return {
                                            name: questionName,
                                            value: optionValue,
                                            label: opt.text,
                                            onChange: handleChange,
                                            onBlur: handleBlur,
                                            selected:
                                              answer?.toString() ===
                                              optionValue,
                                            accompanyingTextField: opt.specify
                                              ? {
                                                  inputMode: 'text',
                                                  name: otherQuestionName,
                                                  onChange: handleChange,
                                                  onBlur: handleBlur,
                                                  type: 'text',
                                                  placeholder:
                                                    otherOptionPlaceholder,
                                                  value: otherAnswer,
                                                  error: otherError,
                                                }
                                              : undefined,
                                          };
                                        }
                                      )}
                                      twoColumn={
                                        question.settings.options.length >= 8
                                      }
                                    />
                                  );
                                }
                                break;
                              case 'checkboxlist':
                                if (question.settings.options) {
                                  formComponent = (
                                    <CheckboxList
                                      items={question.settings.options.map(
                                        (opt) => {
                                          const optionValue =
                                            opt.value ||
                                            (opt.specify
                                              ? otherOptionDefaultValue
                                              : opt.text);
                                          return {
                                            name: questionName,
                                            value: optionValue,
                                            label: opt.text,
                                            onChange: handleChange,
                                            onBlur: handleBlur,
                                            checked: (answer || []).includes(
                                              optionValue
                                            ),
                                            disabled:
                                              question.settings.maxAnswers !==
                                                undefined &&
                                              (answer || []).length ===
                                                question.settings.maxAnswers &&
                                              !(answer || []).includes(
                                                optionValue
                                              ),
                                            accompanyingTextField: opt.specify
                                              ? {
                                                  inputMode: 'text',
                                                  name: otherQuestionName,
                                                  onChange: handleChange,
                                                  onBlur: handleBlur,
                                                  type: 'text',
                                                  placeholder:
                                                    otherOptionPlaceholder,
                                                  value: otherAnswer,
                                                  error: otherError,
                                                }
                                              : undefined,
                                          };
                                        }
                                      )}
                                      twoColumn={
                                        question.settings.options.length >= 8
                                      }
                                    />
                                  );
                                }
                                break;
                              case 'dropdown':
                                formComponent = (
                                  <div>
                                    {question.title && (
                                      <div className="mb-2">
                                        {question.title}
                                      </div>
                                    )}
                                    <Select
                                      name={questionName}
                                      value={
                                        currentAnswers[
                                          questionName
                                        ]?.toString() || ''
                                      }
                                      placeholder={
                                        question.settings.placeholder
                                      }
                                      onBlur={handleBlur}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLSelectElement>
                                      ) =>
                                        (currentAnswers[questionName] =
                                          e.target.value)
                                      }
                                      accompanyingTextField={
                                        question.settings.options?.find(
                                          (opt) => opt.specify
                                        )
                                          ? {
                                              inputMode: 'text',
                                              name: otherQuestionName,
                                              onChange: handleChange,
                                              onBlur: handleBlur,
                                              type: 'text',
                                              placeholder:
                                                otherOptionPlaceholder,
                                              value: otherAnswer,
                                              error: otherError,
                                            }
                                          : undefined
                                      }
                                      options={
                                        question.settings.options
                                          ?.map((opt) => ({
                                            key: opt.value || opt.text,
                                            value: opt.text,
                                            other: opt.specify,
                                          }))
                                          .filter(
                                            (opt) =>
                                              !Object.keys(currentAnswers).find(
                                                (key) =>
                                                  key !== questionName &&
                                                  !opt.other &&
                                                  currentAnswers[key] &&
                                                  opt.value ===
                                                    currentAnswers[key]
                                              )
                                          ) || []
                                      }
                                    />
                                  </div>
                                );
                                break;
                              case 'slider':
                                formComponent = (
                                  <div className="flex pb-5 pt-12">
                                    <div className="mr-8 whitespace-nowrap">
                                      {question.settings.prefix}
                                    </div>
                                    <RangeSlider
                                      minValue={question.settings.min}
                                      maxValue={question.settings.max}
                                      step={question.settings.step}
                                      unit={question.settings.unit}
                                      value={answer}
                                      onChange={handleChange}
                                      name={questionName}
                                    />
                                  </div>
                                );
                                break;
                            }
                            return (
                              <div className="mb-4" key={key}>
                                {formComponent}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {currentStep.showWhyDidYouChooseThisAnswer && (
                        <div
                          ref={questionStep2}
                          className={`transition-opacity duration-500 ${
                            showQuestionStep2
                              ? 'opacity-100'
                              : 'pointer-events-none opacity-0'
                          }`}
                        >
                          <div className="py-4">
                            <Heading
                              level="h2"
                              textStyle="Heading 4"
                              className="mb-2"
                            >
                              Why did you choose this answer?
                            </Heading>
                            <Paragraph variant="base" className="font-medium">
                              (Please explain your choice or click next to continue)
                            </Paragraph>
                          </div>
                            <div className="pb-16">
                            <textarea
                              name={getWhyDidYouChooseThisAnswerQuestionName(
                                currentStep.id
                              )}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={getIn(
                                values,
                                getWhyDidYouChooseThisAnswerQuestionName(
                                  currentStep.id
                                )
                              )}
                              placeholder="Please explain your choice (optional)"
                              maxLength={250}
                              className="flex h-52 w-full rounded border border-black bg-white px-3 py-2 placeholder:font-sans placeholder:text-charcoal focus:bg-marble"
                              tabIndex={!showQuestionStep2 ? -1 : 0}
                            />
                            <Paragraph variant="sm" className="mt-4">
                              Max. {250 -
                              (getIn(
                                values,
                                getWhyDidYouChooseThisAnswerQuestionName(
                                currentStep.id
                                )
                              )?.length || 0)} characters
                            </Paragraph>
                            </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {currentStep.number === 1 && (
                    <button
                      className="mb-4 ml-2 flex items-center font-medium lg:ml-0"
                      type="button"
                      onClick={() => setDisclaimerIsOpen(true)}
                    >
                      Disclaimer
                      <Icon
                        path={mdiInformation}
                        className="ml-3 h-5 w-5 text-green-500"
                      />
                    </button>
                  )}

                  <div className="mt-auto lg:mt-0">
                    <div className="flex w-full justify-center border-t border-t-[#cbcbcb] pt-4 lg:mb-8 lg:px-14">
                      <Button
                        variant="tertiary"
                        className="mr-5"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                      <Button
                        disabled={nextButtonDisabled}
                        className="w-full lg:w-64"
                      >
                        {currentStepIndex >= sortedQuestionsData.length - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {currentStep.number === 1 && (
        <DisclaimerDialog
          open={disclaimerIsOpen}
          onClose={() => setDisclaimerIsOpen(false)}
          className="lg:mx-auto lg:max-w-xl"
        />
      )}
    </>
  );
};
