import { shuffle } from '../../helpers/shuffle/shuffle';
import moment from 'moment-timezone';

export interface QuestionData {
  id: string;
  nextStepId?: string;
  number: number;
  title: string;
  explanationText?: string;
  helpText?: string;
  errorMessage: string;
  questions: Array<Question>;
  showWhyDidYouChooseThisAnswer?: boolean;
  whyDidYouChooseThisAnswer?: string;
}

export interface Question {
  id?: string;
  type: 'decimal' | 'radiolist' | 'checkboxlist' | 'dropdown' | 'slider';
  title?: string;
  settings: QuestionSettings;
  answer: string | string[];
  otherAnswer?: string;
}

export interface QuestionSettings {
  placeholder?: string;
  options?: Array<QuestionOption>;
  randomise?: boolean;
  prefix?: string;
  min?: number;
  max?: number;
  unit?: string;
  step?: number;
  regex?: string;
  maxAnswers?: number;
}

export interface QuestionOption {
  text: string;
  specify?: boolean;
  nextStepId?: string;
  value?: string;
}

const greatestDevelopmentImpactOptions = [
  ...shuffle([
    {
      text: 'Market and economy dislocation',
    },
    {
      text: 'Recession risk',
    },
    {
      text: 'Geopolitical tension',
    },
    {
      text: 'Inflation and tariffs',
    },
    {
      text: 'US/ China relations',
    },
  ]),
  {
    text: 'Other',
    specify: true,
  },
];

const mostInfluentialTechnologyOptions = [
  ...shuffle([
    {
      text: 'Mobile trading applications',
    },
    {
      text: 'Blockchain/Distributed ledger technology',
    },
    {
      text: 'Artificial intelligence/Machine learning',
    },
    {
      text: 'Natural language processing',
    },
    {
      text: 'API Integration',
    },
  ]),
  {
    text: 'Other',
    specify: true,
  },
];

const topMarketConcernsOptions = [
  ...shuffle([
    {
      text: 'Market data access and costs',
    },
    {
      text: 'Access to liquidity',
    },
    {
      text: 'Execution costs',
    },
    {
      text: 'Market information leakage',
    },
    {
      text: 'Regulatory change',
    },
    {
      text: 'Developments of financial market technology',
    },
    {
      text: 'Access to emerging and frontier markets',
    },
  ]),
  {
    text: 'Other',
    specify: true,
  },
];

const productDevelopmentOptions = [
  ...shuffle([
    {
      text: 'Corporate Bonds',
    },
    {
      text: 'Interest Rate Swaps',
    },
    {
      text: 'Exchange Traded Funds',
    },
    {
      text: 'Government Bonds',
    },
    {
      text: 'Commodities',
    },
    {
      text: 'Credit Default Swaps',
    },
    {
      text: 'Equity Derivatives',
    },
    {
      text: 'Equities (Cash)',
    },
  ]),
  {
    text: 'Other',
    specify: true,
  },
];

const greatestDailyChallengeOptions = [
  ...shuffle([
    {
      text: 'Best execution requirements',
    },
    {
      text: 'Liquidity availability',
    },
    {
      text: 'Workflow efficiency',
    },
    {
      text: 'Information leakage',
    },
    {
      text: 'Availability and cost of data',
    },
    {
      text: 'Regulatory changes',
    },
    {
      text: 'Volatile markets',
    },
    {
      text: 'Price transparency',
    },
  ]),
  {
    text: 'Other',
    specify: true,
  },
];

// Get the Survey Year from the Environment Variables if it's there or use the current year
const surveyYear =
  process.env.REACT_APP_SURVEY_YEAR ?? moment().tz('Europe/London').year();
const surveyNextYear =
  process.env.REACT_APP_SURVEY_YEAR != null
    ? parseInt(process.env.REACT_APP_SURVEY_YEAR, 10) + 1
    : moment().tz('Europe/London').year() + 1;

const questionsData: Array<QuestionData> = [
  {
    id: 'GreatestTradingChallenge',
    number: 1,
    title: `What will be your greatest daily trading challenge in ${surveyYear}?`,
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: greatestDailyChallengeOptions,
          randomise: true,
        },
        answer: '',
        otherAnswer: '',
      },
    ],
    showWhyDidYouChooseThisAnswer: true,
  },
  {
    id: 'GreatestDevelopmentImpact',
    number: 2,
    title: `Which potential developments will have the greatest impact on the markets in ${surveyYear}?`,
    helpText: '(Rank in order of importance)',
    errorMessage: 'You must select one option from each dropdown list below',
    questions: [
      {
        id: 'GreatestDevelopmentImpactRank1',
        type: 'dropdown',
        title: '1st rank',
        settings: {
          options: greatestDevelopmentImpactOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
      {
        id: 'GreatestDevelopmentImpactRank2',
        title: '2nd rank',
        type: 'dropdown',
        settings: {
          options: greatestDevelopmentImpactOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
      {
        id: 'GreatestDevelopmentImpactRank3',
        title: '3rd rank',
        type: 'dropdown',
        settings: {
          options: greatestDevelopmentImpactOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
    ],
  },
  {
    id: 'MostInfluentialTechnology',
    number: 3,
    title:
      'In the next three years, which technologies will be most influential for trading?',
    helpText: '(Rank in order of importance)',
    errorMessage: 'You must select one option from each dropdown list below',
    questions: [
      {
        id: 'MostInfluentialTechnologyRank1',
        title: '1st rank',
        type: 'dropdown',
        settings: {
          options: mostInfluentialTechnologyOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
      {
        id: 'MostInfluentialTechnologyRank2',
        title: '2nd rank',
        type: 'dropdown',
        settings: {
          options: mostInfluentialTechnologyOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
      {
        id: 'MostInfluentialTechnologyRank3',
        title: '3rd rank',
        type: 'dropdown',
        settings: {
          options: mostInfluentialTechnologyOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'TopMarketConcerns',
    number: 4,
    title: 'What are your top three market structure concerns?',
    helpText: '(Rank in order of importance)',
    errorMessage: 'You must select one option from each dropdown list below',
    questions: [
      {
        id: 'TopMarketConcernsRank1',
        title: '1st rank',
        type: 'dropdown',
        settings: {
          options: topMarketConcernsOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
      {
        id: 'TopMarketConcernsRank2',
        title: '2nd rank',
        type: 'dropdown',
        settings: {
          options: topMarketConcernsOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
      {
        id: 'TopMarketConcernsRank3',
        title: '3rd rank',
        type: 'dropdown',
        settings: {
          options: topMarketConcernsOptions,
          placeholder: 'Select one',
        },
        answer: '',
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'PercentageETrading',
    number: 5,
    title:
      'What percentage of your trading will be through e-Trading channels?',
    explanationText:
      'This includes API, multi-dealer platforms and single-dealer platforms.',
    helpText: '(Move the slider to indicate your percentage)',
    errorMessage:
      'You must move each slider to select indicate what percentage of your trading will be through e-Trading channels',
    questions: [
      {
        id: 'PercentageETradingThisYear',
        type: 'slider',
        settings: {
          prefix: `In ${surveyYear}`,
          min: 0,
          max: 100,
          unit: '%',
          step: 1,
        },
        answer: '',
      },
      {
        id: 'PercentageETradingNextYear',
        type: 'slider',
        settings: {
          prefix: `In ${surveyNextYear}`,
          min: 0,
          max: 100,
          unit: '%',
          step: 1,
        },
        answer: '',
      },
    ],
  },

  {
    id: 'InstitutionalTradingPlatform',
    number: 6,
    title:
      'Which type of platform do you primarily use for your institutional trading activities?',
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: [
            {
              text: 'Single-dealer platform',
              nextStepId: 'SingleDealerPlatform',
            },
            {
              text: 'Multi-dealer platform',
            },
            {
              text: 'Both',
              nextStepId: 'SingleDealerPlatform',
            },
          ],
          randomise: false,
        },
        answer: '',
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'SingleDealerPlatform',
    number: 6,
    title:
      'What is the most important criteria when selecting a single dealer platform?',
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: [
            {
              text: 'Tailored pricing',
            },
            {
              text: 'Access to liquidity/inventory',
            },
            {
              text: 'Reduction of information leakages',
            },
            {
              text: 'Reduced execution/brokerage costs',
            },
            {
              text: 'Internalization',
            },
            {
              text: 'Multi-Asset product offering',
            },
            {
              text: 'Other',
              specify: true,
            },
          ],
          randomise: true,
        },
        answer: '',
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'FavouriteFeatures',
    number: 7,
    title:
      'Apart from pricing and execution, which features/capabilities are most valuable to you on a trading platform?',
    helpText: '(Select up to three options)',
    errorMessage: 'You must select at least one option from the list below',
    questions: [
      {
        type: 'checkboxlist',
        settings: {
          options: [
            {
              value: 'Ease of access and experience',
              text: 'Ease of access and experience',
            },
            {
              value: 'Sales and trading commentaries e.g. market insights and trade ideas',
              text: 'Sales and trading commentaries e.g. market insights and trade ideas',
            },
            {
              value: 'Educational resources',
              text: 'Educational resources',
            },
            {
              value: 'Customer support',
              text: 'Customer support',
            },
            {
              value: 'Mobile access',
              text: 'Mobile access',
            },
            {
              value: 'Post-Execution position monitoring and actions',
              text: 'Post-Execution position monitoring and actions',
            },
            {
              value: 'Other',
              text: 'Other',
              specify: true,
            },
          ],
          randomise: true,
          maxAnswers: 3,
        },
        answer: [],
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'DataAndAnalyticsTools',
    number: 8,
    title: 'Which data and analytics tools are most valuable to you?',
    helpText: '(Select up to three options)',
    errorMessage: 'You must select at least one option from the list below',
    questions: [
      {
        type: 'checkboxlist',
        settings: {
          options: [
            {
              value: 'Real-time data and analytics',
              text: 'Real-time data and analytics',
            },
            {
              value: 'Predictive / Pre-trade modelling',
              text: 'Predictive / Pre-trade modelling',
            },
            {
              value: 'Analytics and visuals to support trade idea generation',
              text: 'Analytics and visuals to support trade idea generation',
            },
            {
              value: 'Trade cost analysis',
              text: 'Trade cost analysis',
            },
            {
              value: 'Axes',
              text: 'Axes',
            },
            {
              value: 'Bespoke reports',
              text: 'Bespoke reports',
            },
            {
              value: 'Exportable data and API solutions',
              text: 'Exportable data and API solutions',
            },
          ],
          randomise: true,
          maxAnswers: 3,
        },
        answer: [],
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'ProductDevelopmentRank',
    number: 9,
    title:
      'Which of these products do you think will have the most advances in electronic trading in the next twelve months?',
    helpText: '(Rank in order of importance)',
    errorMessage: 'You must select one option from each dropdown list below',
    questions: [
      {
        id: 'ProductDevelopmentRank1',
        type: 'dropdown',
        title: '1st rank',
        settings: {
          options: productDevelopmentOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
      {
        id: 'ProductDevelopmentRank2',
        title: '2nd rank',
        type: 'dropdown',
        settings: {
          options: productDevelopmentOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
      {
        id: 'ProductDevelopmentRank3',
        title: '3rd rank',
        type: 'dropdown',
        settings: {
          options: productDevelopmentOptions,
          placeholder: 'Select one',
        },
        answer: '',
      },
    ],
  },
  {
    id: 'CryptoTradingStatus',
    number: 10,
    title:
      'Which option best describes your institutional work with crypto / digital coins?',
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: [
            {
              text: 'I am currently trading crypto / digital coins',
            },
            {
              text: 'I plan to trade crypto / digital coins',
            },
            {
              text: 'I have no plans to trade crypto / digital coins',
            },
          ],
        },
        answer: '',
      },
    ],
  },

  {
    id: 'CurrentTradedProducts',
    number: 11,
    title: 'Which products do you trade at work/ for your entity?',
    helpText: '(Select all that apply)',
    errorMessage: 'You must select at least one option from the list below',
    questions: [
      {
        type: 'checkboxlist',
        settings: {
          options: [
            {
              value: 'TradeFX',
              text: 'FX',
            },
            {
              value: 'TradeFXAndPreciousMetalsOptions',
              text: 'FX & Precious metals options',
            },
            {
              value: 'TradeG10Rates',
              text: 'G10 rates',
            },
            {
              value: 'TradeEMRates',
              text: 'EM rates',
            },
            {
              value: 'TradeCreditSpread',
              text: 'Credit / Spread',
            },
            {
              value: 'TradeCommodities',
              text: 'Commodities (including precious metals)',
            },
            {
              value: 'TradeFuturesAndOptions',
              text: 'Futures & Options',
            },
            {
              value: 'TradeCashEquities',
              text: 'Cash equities',
            },
            {
              value: 'TradeEquityDerivatives',
              text: 'Equity derivatives',
            },
            {
              value: 'TradeCryptoDigitalCoins',
              text: 'Crypto / Digital coins',
            },
            {
              value: 'TradeOther',
              text: 'Other',
              specify: true,
            },
          ],
          randomise: true,
        },
        answer: [],
        otherAnswer: '',
      },
    ],
  },
  {
    id: 'TraderServiceLength',
    number: 12,
    title: 'How long have you been an institutional trader?',
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: [
            {
              text: 'Less than 2 years',
            },
            {
              text: '2 to 5 years',
            },
            {
              text: '6 to 10 years',
            },
            {
              text: '11 to 15 years',
            },
            {
              text: '16 to 20 years',
            },
            {
              text: 'More than 20 years',
            },
            {
              text: 'Prefer not to answer',
            },
          ],
        },
        answer: '',
      },
    ],
  },

  {
    id: 'YourAge',
    number: 13,
    title: 'How old are you?',
    helpText: '(Select one option)',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'radiolist',
        settings: {
          options: [
            {
              text: '18-26',
            },
            {
              text: '27-42',
            },
            {
              text: '43-57',
            },
            {
              text: '58+',
            },
            {
              text: 'None of the above',
            },
            {
              text: 'Prefer not to answer',
            },
          ],
        },
        answer: '',
      },
    ],
  },
  {
    id: 'ResidentCountry',
    number: 14,
    title: 'Which location do you currently reside in?',
    helpText:
      'Scroll or type the first letter of your country of residence to locate it in the list',
    errorMessage: 'You must select one option from the list below',
    questions: [
      {
        type: 'dropdown',
        settings: {
          options: [
            { text: 'Hong Kong SAR, China' },
            { text: 'Singapore' },
            { text: 'United Arab Emirates' },
            { text: 'United Kingdom' },
            { text: 'United States' },
            { text: '---' },
            { text: 'Albania' },
            { text: 'Algeria' },
            { text: 'Andorra' },
            { text: 'Angola' },
            { text: 'Argentina' },
            { text: 'Armenia' },
            { text: 'Australia' },
            { text: 'Austria' },
            { text: 'Azerbaijan' },
            { text: 'Bahamas' },
            { text: 'Bahrain' },
            { text: 'Bangladesh' },
            { text: 'Barbados' },
            { text: 'Belarus' },
            { text: 'Belgium' },
            { text: 'Bermuda' },
            { text: 'Bhutan' },
            { text: 'Bolivia' },
            { text: 'Bosnia-Herzegovina' },
            { text: 'Botswana' },
            { text: 'Brazil' },
            { text: 'British Virgin Islands' },
            { text: 'Bulgaria' },
            { text: 'Cameroon' },
            { text: 'Canada' },
            { text: 'Chile' },
            { text: 'China' },
            { text: 'Colombia' },
            { text: 'Costa Rica' },
            { text: "Cote D'Ivoire" },
            { text: 'Croatia' },
            { text: 'Cyprus' },
            { text: 'Czech Republic' },
            { text: 'Denmark' },
            { text: 'Djibouti' },
            { text: 'Dominican Republic' },
            { text: 'Ecuador' },
            { text: 'Egypt' },
            { text: 'Estonia' },
            { text: 'Ethiopia' },
            { text: 'Finland' },
            { text: 'France' },
            { text: 'Georgia' },
            { text: 'Germany' },
            { text: 'Ghana' },
            { text: 'Greece' },
            { text: 'Guatemala' },
            { text: 'Guernsey' },
            { text: 'Hungary' },
            { text: 'Iceland' },
            { text: 'India' },
            { text: 'Indonesia' },
            { text: 'Iran' },
            { text: 'Iraq' },
            { text: 'Ireland' },
            { text: 'Israel' },
            { text: 'Italy' },
            { text: 'Japan' },
            { text: 'Jordan' },
            { text: 'Kazakhstan' },
            { text: 'Kenya' },
            { text: 'Kuwait' },
            { text: 'Latvia' },
            { text: 'Lebanon' },
            { text: 'Libya' },
            { text: 'Liechtenstein' },
            { text: 'Lithuania' },
            { text: 'Luxembourg' },
            { text: 'Macau' },
            { text: 'Macedonia' },
            { text: 'Madagascar' },
            { text: 'Malaysia' },
            { text: 'Malta' },
            { text: 'Mauritius' },
            { text: 'Mexico' },
            { text: 'Mongolia' },
            { text: 'Montenegro, Republic of' },
            { text: 'Morocco' },
            { text: 'Namibia' },
            { text: 'Netherlands Antilles' },
            { text: 'Netherlands' },
            { text: 'New Zealand' },
            { text: 'Nigeria' },
            { text: 'Norway' },
            { text: 'Oman' },
            { text: 'Pakistan' },
            { text: 'Panama' },
            { text: 'Paraguay' },
            { text: 'Peru' },
            { text: 'Philippines' },
            { text: 'Poland' },
            { text: 'Portugal' },
            { text: 'Puerto Rico' },
            { text: 'Qatar' },
            { text: 'Romania' },
            { text: 'Russia' },
            { text: 'Saudi Arabia' },
            { text: 'Serbia' },
            { text: 'Slovakia' },
            { text: 'Slovenia' },
            { text: 'South Africa' },
            { text: 'South Korea' },
            { text: 'Spain' },
            { text: 'Sri Lanka' },
            { text: 'Sweden' },
            { text: 'Switzerland' },
            { text: 'Taiwan, China' },
            { text: 'Tanzania' },
            { text: 'Thailand' },
            { text: 'Togo' },
            { text: 'Trinidad and Tobago' },
            { text: 'Tunisia' },
            { text: 'Turkey' },
            { text: 'Uganda' },
            { text: 'Ukraine' },
            { text: 'Uruguay' },
            { text: 'Uzbekistan' },
            { text: 'Vatican City State' },
            { text: 'Venezuela' },
            { text: 'Vietnam' },
            { text: 'Virgin Islands' },
            { text: 'Zambia' },
            { text: 'Other' },
          ],
          placeholder: 'Select one',
        },
        answer: '',
      },
    ],
  },
];

const sortedQuestionsData = [...questionsData];
sortedQuestionsData.sort((a, b) =>
  a.number > b.number ? 1 : a.number === b.number ? 0 : -1
);

export default sortedQuestionsData;
