// Dependencies
import axios from 'axios';
import { getIn } from 'formik';
import sortedQuestionsData from '../../../features/questions/questionsData';
import {
  answerIsOtherOption,
  getOtherQuestionName,
  getQuestionName,
  getWhyDidYouChooseThisAnswerQuestionName,
} from '../../../helpers/questionHelper/questionHelper';

// Models
import { Answer } from '../QuestionsApi.model';

export class QuestionsApiClient {
  private baseUri: string;

  constructor(baseUri: string) {
    this.baseUri = baseUri;
  }

  public async startSurvey(
    userReferenceId: string,
    source: string,
    isMobile?: boolean
  ) {
    const response = await axios.post(`${this.baseUri}StartSurvey`, {
      participantId: userReferenceId,
      startTime: new Date().toISOString(),
      source: source.substring(0, 40),
      isMobile: isMobile,
    });

    return response;
  }

  public async postAnswers(
    userReferenceId: string,
    stepId: string,
    nextStepId: string,
    answers: any
  ) {
    const baseAnswer: Answer = {
      participantId: userReferenceId,
      questionId: stepId,
      nextQuestionId: nextStepId,
      timestamp: new Date().toISOString(),
      whyDidYouChooseThisAnswer: getIn(
        answers,
        getWhyDidYouChooseThisAnswerQuestionName(stepId)
      ),
    };

    const answersForApi: Answer[] = [];

    const currentStepQuestion = sortedQuestionsData.find(
      (step) => step.id === stepId
    );

    if (!currentStepQuestion)
      throw new Error('Question with specified ID not found');

    currentStepQuestion.questions.forEach((question, index) => {
      const answerForApi = { ...baseAnswer };
      if (question.id) answerForApi.questionId = question.id;

      const questionName = getQuestionName(question, index);
      const questionAnswer = getIn(answers, questionName);

      const otherQuestionName = getOtherQuestionName(question, index);
      const otherAnswer = getIn(answers, otherQuestionName);
      const setOtherAnswer = answerIsOtherOption(question, questionAnswer);

      if (currentStepQuestion.id === 'CurrentTradedProducts') {
        answerForApi.productsTraded = questionAnswer;
        answerForApi.tradedOtherDescription = setOtherAnswer ? otherAnswer : '';
        answersForApi.push(answerForApi);
      } else if (currentStepQuestion.id === 'FavouriteFeatures' || currentStepQuestion.id === 'DataAndAnalyticsTools') {
        for (let i = 0; i < 3; i++) {
          answersForApi.push({
            ...answerForApi,
            questionId: answerForApi.questionId + i,
            answer: questionAnswer.length >= i ? questionAnswer[i] : undefined,
          });
        }
      } else {
        answerForApi.answer = (
          setOtherAnswer && otherAnswer ? otherAnswer : questionAnswer
        ).toString();
        answersForApi.push(answerForApi);
      }
    });

    const response = await axios.post(
      `${this.baseUri}QuestionAnswer`,
      answersForApi
    );

    return response;

    // check if response is valid
  }
}

export default function createClient(): QuestionsApiClient {
  if (process.env.REACT_APP_QUESTIONS_API_BASE_URI)
    return new QuestionsApiClient(process.env.REACT_APP_QUESTIONS_API_BASE_URI);
  throw new Error('Questions API URL not specified');
}
