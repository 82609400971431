import { InputProps } from '../Input/Input.model';

export interface Sizes {
  small: string;
  medium: string;
  large: string;
}

export const sizes: Sizes = Object.freeze({
  small: 'w-full lg:w-[328px] lg:max-w-full',
  medium: 'w-full lg:w-[544px] lg:max-w-full',
  large: 'w-full',
});

export interface RadioProps {
  /**
   * Disables the field.
   */
  disabled?: boolean;

  /**
   * Sets the field label.
   */
  label: string | JSX.Element;

  /**
   * Sets the field name.
   */
  name: string;

  /**
   * Field on change event handler.
   */
  onChange: Function;

  /**
   * Field on blur event handler.
   */
  onBlur: Function;

  /**
   * Sets the field as readonly.
   */
  readOnly?: boolean;

  /**
   * Sets the field as required.
   */
  required?: boolean;

  /**
   * Sets the item as selected by default.
   */
  selected?: boolean;

  /**
   * Sets the field value.
   */
  value: string;

  /**
   * Sets the field size.
   */
  size?: keyof typeof sizes;

  /**
   * Sets the field as having an accompanying text field.
   */
  accompanyingTextField?: InputProps;
}
