// Dependencies
import React, { useEffect, useRef, useState } from 'react';

// Helpers
import { uniqueId } from '../../../../helpers/uniqueId/uniqueId';
import { getDescribedBy } from '../../../../helpers/getDescribedBy/getDescribedBy';

// Components
import { Input } from '../Input/Input';

// Models
import { RadioProps, sizes } from './Radio.model';

/**
 * Input Component
 */
export const Radio: React.FC<RadioProps> = ({
  disabled,
  label,
  name,
  onChange,
  onBlur,
  readOnly,
  required,
  selected,
  value,
  size,
  accompanyingTextField,
}) => {
  // Set The Initial State
  const [id] = useState(uniqueId());
  const [describedBy, setDescribedBy] = useState('');

  // Set the Tool tip on ID Change
  useEffect(() => {
    setDescribedBy(getDescribedBy({ id, name }));
  }, [id, name]);

  const accompanyingTextFieldRef = useRef<HTMLDivElement>(null);
  const accompanyingTextFieldInput =
    accompanyingTextFieldRef.current?.querySelector(
      `[name=${accompanyingTextField?.name}]`
    ) as HTMLInputElement;

  useEffect(() => {
    if (selected && accompanyingTextFieldInput) {
      accompanyingTextFieldInput.focus();
      accompanyingTextFieldInput.select();
    }
  }, [selected, accompanyingTextFieldInput]);

  // Render the component.
  return (
    <div className="relative">
      <div className={`mb-2 inline-block lg:mb-4 ${size && sizes[size]}`}>
        <div className="group relative flex w-full flex-col">
          <input
            id={id}
            type="radio"
            name={name}
            onChange={onChange.bind(this)}
            onBlur={onBlur.bind(this)}
            aria-describedby={describedBy}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
            value={value}
            checked={selected}
            className={`peer absolute top-0 left-0 right-0 bottom-0 m-0 block h-full w-full appearance-none rounded border border-titanium bg-white bg-none py-2.5 px-2 enabled:checked:border-jet enabled:checked:bg-charcoal enabled:hover:border-grey-800 enabled:hover:bg-marble enabled:hover:checked:border-jet enabled:hover:checked:bg-charcoal`}
          />
          <label
            htmlFor={id}
            className="relative flex cursor-pointer items-center justify-start rounded py-2.5 px-2 peer-enabled:peer-checked:text-white peer-enabled:peer-hover:text-inherit peer-enabled:peer-checked:peer-hover:text-white"
          >
            <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-titanium bg-white group-hover:peer-enabled:[label_&]:border-green-600 peer-enabled:[label_&]:group-hover:bg-marble peer-enabled:peer-checked:[label_&]:border-green-500 peer-enabled:peer-checked:[label_&]:bg-marble">
              <div className="hidden h-4 w-4 rounded-full bg-green-500 peer-checked:[label_&]:block peer-disabled:[label_&]:bg-titanium"></div>
            </div>
            <div className="ml-2 leading-5">{label}</div>
          </label>
        </div>
      </div>

      {accompanyingTextField && selected && (
        <div
          className={`mb-2 ${
            size && sizes[size]
          } lg:absolute lg:top-11 lg:my-4`}
          ref={accompanyingTextFieldRef}
        >
          <Input {...accompanyingTextField} />
        </div>
      )}
    </div>
  );
};
