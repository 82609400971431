import { mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  errorValue,
  otherErrorMessage,
  otherErrorValue,
} from '../../../../helpers/validationHelper/validationHelper';
import { Paragraph } from '../../../Atoms/Paragraph/Paragraph';
import { ErrorMessageProps } from './ErrorMessage.model';

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  currentStep,
  errors,
  touched,
}) => {
  let firstErrorQuestionName = Object.keys(errors).find(
    (questionName) =>
      !!errors[questionName] && Object.keys(touched).includes(questionName)
  );
  if (!firstErrorQuestionName) return <></>;

  let firstError = errors[firstErrorQuestionName];
  let errorMessage = '';
  if (firstError === errorValue) {
    errorMessage = currentStep.errorMessage;
  } else if (firstError === otherErrorValue) {
    errorMessage = otherErrorMessage;
  }

  if (!errorMessage) return <></>;

  return (
    <Paragraph className="mt-4 flex bg-ruby-700 bg-opacity-10 p-2 text-base leading-5 text-ruby-700">
      <Icon
        path={mdiAlertCircle}
        size="1.25rem"
        className="mr-2 flex-shrink-0"
      />
      {errorMessage}
    </Paragraph>
  );
};
