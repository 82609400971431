// Dependencies
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiInformation } from '@mdi/js';

// Redux + Slices
import { selectCurrentIndex, setStepIndex } from '../features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../hooks';

// Components
import { Button } from '../components/Atoms/Button/Button';
import { Heading } from '../components/Atoms/Heading/Heading';
import { Paragraph } from '../components/Atoms/Paragraph/Paragraph';
import { Image } from '../components/Molecules/Image/Image';
import { DisclaimerDialog } from '../components/Organisms/DisclaimerDialog/DisclaimerDialog';

// Assets
import logo from '../assets/svg/JPM_Brown_Logo.svg';
import introImage from '../assets/img/intro.jpg';
import moment from 'moment-timezone';

export const Intro = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false);

  const currentStepIndex = useAppSelector(selectCurrentIndex);
  if (!currentStepIndex) {
    if (new URLSearchParams(window.location.search).get('si') === '1') {
      dispatch(setStepIndex(0));
      return <Navigate to="/steps/1" />;
    }
  }

  const surveyYear = process.env.REACT_APP_SURVEY_YEAR ?? moment().tz("Europe/London").year();

  return (
    <>
      <div className="h-full w-full bg-limestone lg:flex">
        <div className="relative h-[53vh] overflow-hidden lg:block lg:h-full lg:w-[57vw]">
          <Image
            url={introImage}
            width={823}
            height={960}
            aspectRatio="absolute"
          />
        </div>
        <div className="px-4 py-6 lg:order-first lg:mr-auto lg:flex lg:flex-col lg:justify-center lg:px-10">
          <div className="mb-4 h-[30px] w-[146px]">
            <Image url={logo} width={146} height={30} />
          </div>
          <Heading level="h2" textStyle="Heading 3" className="mb-3 lg:hidden">
            What are your e-Trading
            <br />
            <span className="text-brown-500">predictions for {surveyYear}?</span>
          </Heading>
          <Heading
            level="h2"
            textStyle="Heading 2"
            className="mb-4 hidden lg:block"
          >
            What are your e-Trading
            <br />
            <span className="text-brown-500">predictions for {surveyYear}?</span>
          </Heading>
          <Paragraph className="mb-8">
            Share your expert views in under <br />
            <strong>90&nbsp;seconds</strong> as part of The e-Trading Edit.<br /><br />
            <strong>For institutional and professional traders only.</strong><br />
          </Paragraph>
          <Button
            className="mb-8 w-full normal-case lg:w-64"
            onClick={() => {
              dispatch(setStepIndex(0));
              navigate('/steps/1');
            }}
          >
            Get started
          </Button>
          <button
            className="ml-2 mb-8 flex items-center font-medium lg:ml-0"
            type="button"
            onClick={() => setDisclaimerIsOpen(true)}
          >
            Disclaimer
            <Icon
              path={mdiInformation}
              className="ml-3 h-5 w-5 text-green-500"
            />
          </button>
        </div>
      </div>

      <DisclaimerDialog
        open={disclaimerIsOpen}
        onClose={() => setDisclaimerIsOpen(false)}
        className="lg:right-[57vw] lg:mx-10 lg:translate-y-1/2"
      />
    </>
  );
};
