import moment from "moment-timezone";

// a function to take a opening date time and a closing date time and checks against the current date time in GMT and returns a boolean
export const isSurveyOpen = (openDateTime: string, closeDateTime: string) => {
    if (openDateTime !== '' && closeDateTime !== '') {
        const openDate = moment(openDateTime).format('YYYYMMDDHHmmss');
        const closeDate = moment(closeDateTime).format('YYYYMMDDHHmmss');
        const currentDate = moment().tz("Europe/London").format('YYYYMMDDHHmmss');

        return currentDate >= openDate && currentDate <= closeDate;
    }

    // if the openDateTime or closeDateTime is not set, return false i.e. the survey is not open
    return false;
  };