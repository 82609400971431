// Dependencies
import React from 'react';

// Components
import { Checkbox } from '../Checkbox/Checkbox';

// Models
import { CheckboxListProps } from './CheckboxList.model';

/**
 * Checkbox List Component
 */
export const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  twoColumn,
}) => {
  return (
    <div className={`${twoColumn && 'lg:columns-2'}`}>
      {items.map((item, index) => {
        return (
          <Checkbox
            {...item}
            size={twoColumn ? 'small' : 'medium'}
            key={`checkbox-${index}`}
          />
        );
      })}
    </div>
  );
};
