// Dependencies
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';

// Components
import { Dialog } from '@headlessui/react';

// Models
import { DisclaimerDialogProps } from './DisclaimerDialog.model';

export const DisclaimerDialog: React.FC<DisclaimerDialogProps> = ({
  open,
  onClose,
  className,
}) => {
  return (
    <Dialog open={open} onClose={onClose.bind(this)} className="relative z-50">
      <div
        className="fixed inset-0 bg-grey-800 opacity-50"
        aria-hidden="true"
      />

      <Dialog.Panel
        className={`fixed bottom-0 left-0 right-0 z-50 bg-white p-8 lg:bottom-1/2 lg:px-5 ${className}`}
      >
        <div className="h-10 text-right text-green-600">
          <button onClick={onClose.bind(this)}>
            <span className="sr-only">Close</span>
            <Icon path={mdiClose} size="1.5rem" />
          </button>
        </div>
        <p className="mb-6">
          Your responses will be collected by Lab Digital Services Limited on an
          anonymous basis.
        </p>
        <p className="mb-6">
          While certain employees at J.P. Morgan will have the ability to
          identify your individual responses, we will not share this information
          outside the relevant teams. Your data will be treated in accordance with the J.P. Morgan global privacy policy which
          can be found{' '}
          <a
            href="https://www.jpmorgan.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          . 
        </p>
        <p>Survey results will be published to participants solely on an
        aggregate basis.</p>
      </Dialog.Panel>
    </Dialog>
  );
};
