// Dependencies
import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

// Redux
import {
  selectUserReferenceId,
  selectUserSource,
  setUserReferenceId,
  setUserSource,
} from '../../features/app/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';

// Components
import { Intro } from '../../views';
import { CustomErrorPage } from '../../views/404';
import { Complete } from '../../views/complete';
import { Layout } from '../../views/layout';
import { StepContainer } from '../StepContainer/StepContainer';
import { Closed } from '../../views/closed';
import { isSurveyOpen } from '../../helpers/isSurveyOpen/isSurveyOpen';

export const MainContainer = () => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserReferenceId);
  if (!userId) {
    dispatch(setUserReferenceId(uuid()));
  }

  const source = useAppSelector(selectUserSource);
  if (!source) {
    dispatch(
      setUserSource(
        new URLSearchParams(window.location.search).get('source') || ''
      )
    );
  }

  // Get the environment variables settings to determine if the survey is open
  const isOpen = isSurveyOpen(process.env.REACT_APP_SURVEY_OPEN_DATETIME ?? '', process.env.REACT_APP_SURVEY_CLOSE_DATETIME ?? '');

  return (
    <BrowserRouter>
      {isOpen === true && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Intro />} />
            <Route path="steps/:stepId" element={<StepContainer />} />
            <Route path="complete" element={<Complete />} />

            <Route path="*" element={<CustomErrorPage />} />
          </Route>
        </Routes>
      )}
      {isOpen === false && (
        <Routes>
          <Route path="/" element={<Closed />} />
          <Route path="*" element={<Closed />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};