import { getIn, setIn } from 'formik';
import { Question } from '../../features/questions/questionsData';
import {
  answerIsOtherOption,
  getOtherQuestionName,
  getQuestionName,
} from '../questionHelper/questionHelper';

export const errorValue = 'ERROR';

export const otherErrorValue = 'OTHERERROR';

export const otherErrorMessage = 'Please specify before proceeding';

export const answerIsValid = (
  answer: number | string | string[] | undefined
): boolean => {
  if (typeof answer === 'number') {
    return !isNaN(answer);
  } else if (typeof answer === 'string') {
    return answer !== '';
  } else if (Array.isArray(answer)) {
    return answer.length > 0 && !answer.find((ans) => !answerIsValid(ans));
  } else {
    return false;
  }
};

export const getErrors = (values: any, questions: Question[]): any => {
  let errors = {};
  questions.forEach((question, index) => {
    const questionName = getQuestionName(question, index);
    let answer: number | string | string[] = getIn(values, questionName);

    if (answerIsOtherOption(question, answer)) {
      const otherQuestionName = getOtherQuestionName(question, index);
      const otherAnswer = getIn(values, otherQuestionName);
      if (!otherAnswer) {
        errors = setIn(errors, otherQuestionName, otherErrorValue);
      }
    } else if (!answerIsValid(answer)) {
      errors = setIn(errors, questionName, errorValue);
    }
  });

  return errors;
};
